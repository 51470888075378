<template>
  <!-- Login Form -->
  <div>
    <form id="formItems" class="needs-validation" autocomplete="off" novalidate>
      <input
        v-model="form.username"
        @blur="checkDupName()"
        type="text"
        id="username"
        ref="username"
        class="form-control fadeIn second"
        placeholder="Username"
        :disabled="isGoogleAuth"
        :style="isGoogleAuth ? 'background-color: lightGrey' : ''"
        required
      />
      <div class="invalid-feedback text-end pe-8 pb-3">
        <span>กรุณากรอก</span>
      </div>
      <div v-if="isDupName" class="text-end pe-11 pb-3" style="color: #f1416c">
        <span>Username ถูกใช้งานไปแล้ว</span>
      </div>

      <input
        v-model="form.email"
        @blur="checkDupEmail()"
        type="text"
        id="email"
        class="form-control fadeIn second"
        placeholder="Email"
        :disabled="isGoogleAuth"
        :style="isGoogleAuth ? 'background-color: lightGrey' : ''"
        required
      />
      <div class="invalid-feedback text-end pe-8 pb-3">
        <span>กรุณากรอก</span>
      </div>
      <div v-if="isDupUser" class="text-end pe-11 pb-3" style="color: #f1416c">
        <span>E-mail ถูกใช้งานไปแล้ว</span>
      </div>

      <!-- password -->
      <div style="position: relative">
        <input
          v-model="form.password"
          @input.prevent="passwordChange(form.password)"
          :type="showPassword ? 'text' : 'password'"
          id="password"
          class="form-control fadeIn third"
          placeholder="Password"
          required
          :disabled="isGoogleAuth"
          maxlength="16"
          :style="isGoogleAuth ? 'background-color: lightGrey' : ''"
        />
        <button
          type="button"
          class="fadeIn third"
          @click.prevent="showPassword = !showPassword"
          id="btnPass"
          @mouseover="colorEyePass = 'color: white;'"
          @mouseleave="colorEyePass = 'color: grey;'"
          style="
            cursor: pointer;
            position: absolute;
            top: 30%;
            right: 10%;
            border-radius: 55%;
            border-color: transparent;
          "
        >
          <i class="far fa-eye" :style="colorEyePass"></i>
        </button>
      </div>
      <div class="row d-flex justify-space-between">
        <div class="col-4 text-start ps-14 fadeIn third">
          <span>{{ counterPass }} / 16</span>
        </div>
        <div
          v-if="textPassError.length"
          class="col-8 text-end pe-14"
          style="color: #f1416c"
        >
          <div v-for="item in textPassError" :key="item">{{ item.err }}</div>
        </div>
      </div>
      <div v-if="!form.password" class="invalid-feedback text-end pe-8 pb-3">
        <span>กรุณากรอก</span>
      </div>

      <!-- confirmPassword -->
      <div style="position: relative">
        <input
          v-model="form.confirmPassword"
          @input="confirmPasswordChange(form.confirmPassword)"
          :type="showConfirmPassword ? 'text' : 'password'"
          id="confirmPassword"
          class="form-control fadeIn third"
          placeholder="Confirm Password"
          :disabled="isGoogleAuth ? true : passwordEmpty ? true : false"
          required
          :style="
            isGoogleAuth
              ? 'background-color: lightGrey'
              : !passwordEmpty
              ? ''
              : 'background-color: lightGrey'
          "
          maxlength="16"
        />
        <button
          type="button"
          class="fadeIn third"
          @click.prevent="showConfirmPassword = !showConfirmPassword"
          id="btnConfirmPass"
          @mouseover="colorEyeConfirmPass = 'color: white;'"
          @mouseleave="colorEyeConfirmPass = 'color: grey;'"
          style="
            cursor: pointer;
            position: absolute;
            top: 30%;
            right: 10%;
            border-radius: 55%;
            border-color: transparent;
          "
        >
          <i class="far fa-eye" :style="colorEyeConfirmPass"></i>
        </button>
      </div>

      <div class="row d-flex justify-space-between">
        <div class="col-4 text-start ps-14 fadeIn third">
          <span>{{ counterConfirmPass }} / 16</span>
        </div>
        <div class="col-8 text-end pe-14" style="color: #f1416c">
          <span v-if="textConfirmError">{{ textConfirmError }}</span>
        </div>
      </div>
      <div
        v-if="!form.confirmPassword"
        class="invalid-feedback text-end pe-8 pb-3"
      >
        <span>กรุณากรอก</span>
      </div>

      <!-- approvalPassword -->
      <div style="position: relative">
        <input
          v-model="form.approvalPassword"
          @input="approvalPasswordChange(form.approvalPassword)"
          :type="showApprovalPassword ? 'text' : 'password'"
          id="approvalPassword"
          class="form-control fadeIn third"
          placeholder="รหัสอนุมัติเอกสาร"
          required
          maxlength="6"
        />
        <button
          type="button"
          class="fadeIn third"
          @click.prevent="showApprovalPassword = !showApprovalPassword"
          id="approvalPass"
          @mouseover="colorEyeApprovePass = 'color: white;'"
          @mouseleave="colorEyeApprovePass = 'color: grey;'"
          style="
            cursor: pointer;
            position: absolute;
            top: 30%;
            right: 10%;
            border-radius: 55%;
            border-color: transparent;
          "
        >
          <i class="far fa-eye" :style="colorEyeApprovePass"></i>
        </button>
      </div>

      <div class="row d-flex justify-space-between">
        <div class="col-4 text-start ps-14 fadeIn third">
          <span>{{ counterApprovalPass }} / 6</span>
        </div>
        <div class="col-8 text-end pe-14" style="color: #f1416c">
          <span v-if="textApprovalError">{{ textApprovalError }}</span>
        </div>
      </div>
      <div
        v-if="!form.approvalPassword"
        class="invalid-feedback text-end pe-8 pb-3"
      >
        <span>กรุณากรอก</span>
      </div>

      <input
        v-model="form.firstName"
        type="text"
        id="first-name"
        class="fadeIn fourth"
        placeholder="First Name"
      />
      <input
        v-model="form.lastName"
        type="text"
        id="last-name"
        class="fadeIn fourth"
        placeholder="Last Name"
      />
      <input
        v-model="form.telNumber"
        type="text"
        id="tel-number"
        class="fadeIn fifth"
        placeholder="Phone Number"
        required
      />

      <select
        v-model="form.type"
        class="form-select fadeIn fifth"
        aria-label="Default select example"
      >
        <option value="0">+ เปิดกิจการใหม่</option>
        <option value="1">+ เพิ่มผู้ใช้งานระบบ</option>
      </select>

      <select
        v-model="form.role"
        :disabled="form.type === '0' ? true : false"
        class="form-select fadeIn fifth"
        aria-label="Default select example"
      >
        <!-- <option>SYSTEM ADMIN</option> -->
        <option v-if="form.type === '0'">BUSINESS OWNER</option>
        <option>BUSINESS DIRECTOR</option>
        <option>MANAGER</option>
        <option>STAFF</option>
      </select>

      <input
        @click="submit"
        type="submit"
        class="fadeIn fifth mt-10"
        value="ถัดไป"
      />
    </form>
  </div>
</template>

<script>
import loginApi from "@/api/user/";
import companyApi from "@/api/user/";

import { Decode, Encode } from "@/services";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  props: {
    form: Object,
    step: Number,
    isGoogleAuth: Boolean,
  },
  emits: ["changeStep"],
  data: () => ({
    typePass: "password",
    showPassword: false,
    showConfirmPassword: false,
    showApprovalPassword: false,
    isValid: false,

    companyItems: [],

    passwordEmpty: true,

    counterPass: 0,
    textPassError: [],

    counterConfirmPass: 0,
    textConfirmError: "",

    counterApprovalPass: 0,
    textApprovalError: "",

    isDupUser: false,
    isDupName: false,
    responseDupErr: "",

    colorEyePass: "color: grey;",
    colorEyeConfirmPass: "color: grey;",
    colorEyeApprovePass: "color: grey;",
  }),

  watch: {
    "form.type"(val) {
      if (val === "0") {
        this.form.role = "BUSINESS OWNER";
      } else {
        this.form.role = "MANAGER";
      }
    },
  },

  mounted() {
    this.$refs.username.focus();
    this.setCountByAuth();
  },

  methods: {
    setCountByAuth() {
      if (this.$route.query.auth) {
        this.counterPass = 16;
        this.counterConfirmPass = 16;
      }
    },

    async submit() {
      await this.validateForm();
      await this.checkFormIsEmpty();
      if (this.isDupUser) {
        Swal.fire({
          icon: "error",
          title: this.responseDupErr,
          confirmButtonText: "ตกลง",
          timer: 4000,
        });
      }

      if (this.isDupName) {
        Swal.fire({
          icon: "error",
          title: this.responseDupErr,
          confirmButtonText: "ตกลง",
          timer: 4000,
        });
      }

      if (this.isValid && !this.isDupUser && !this.isDupName) {
        this.$emit("changeStep", 1);
      }
    },
    checkFormIsEmpty() {
      !this.form.username
        ? (this.isValid = false)
        : !this.form.password
        ? (this.isValid = false)
        : !this.form.approvalPassword
        ? (this.isValid = false)
        : this.form.password.length < 8
        ? (this.isValid = false)
        : !this.form.confirmPassword
        ? (this.isValid = false)
        : this.form.confirmPassword.length < 8
        ? (this.isValid = false)
        : this.form.confirmPassword !== this.form.password
        ? (this.isValid = false)
        : !this.form.email
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },

    passwordChange(val) {
      if (val) {
        this.counterPass = val.length;

        this.validatePass(val);
        // }
      } else {
        this.counterPass = 0;
        this.passwordEmpty = true;
      }
    },

    validatePass(val) {
      const number = /[0-9]/;
      const lowerCase = /[a-z]/;
      const upperCase = /[A-Z]/;

      let haveNumber = false;
      let haveLowerCase = false;
      let haveUpperCase = false;
      let haveLength = false;

      if (val.length < 8) {
        haveLength = false;
        this.passwordEmpty = true;
        this.textConfirmError = "";
        this.form.confirmPassword = "";
        this.counterConfirmPass = 0;
      } else {
        haveLength = true;
      }
      for (let i = 0; i < val.length; i++) {
        if (number.test(val[i])) {
          haveNumber = true;
        }

        if (lowerCase.test(val[i])) {
          haveLowerCase = true;
        }

        if (upperCase.test(val[i])) {
          haveUpperCase = true;
        }
      }
      // }

      if (!haveLength) {
        const findItem = this.textPassError.find((data) => data.id === 1);

        findItem
          ? ""
          : this.textPassError.push({
              err: "รหัสผ่านต้องมีความยาว 8 - 16 หลัก",
              id: 1,
            });
      } else {
        const findItem = this.textPassError.find((data) => data.id === 1);

        if (findItem) {
          this.textPassError = this.textPassError.filter(
            (data) => data.id != findItem.id
          );
        }
      }

      if (!haveNumber) {
        const findItem = this.textPassError.find((data) => data.id === 2);

        findItem
          ? ""
          : this.textPassError.push({
              err: "รหัสต้องประกอบไปด้วยตัวเลข (0 - 9)",
              id: 2,
            });
      } else {
        const findItem = this.textPassError.find((data) => data.id === 2);

        if (findItem) {
          this.textPassError = this.textPassError.filter(
            (data) => data.id != findItem.id
          );
        }
      }

      if (!haveLowerCase) {
        const findItem = this.textPassError.find((data) => data.id === 3);

        findItem
          ? ""
          : this.textPassError.push({
              err: "รหัสต้องมีอักษรภาษาอังกฤษ พิมพ์เล็ก (a - z)",
              id: 3,
            });
      } else {
        const findItem = this.textPassError.find((data) => data.id === 3);

        if (findItem) {
          this.textPassError = this.textPassError.filter(
            (data) => data.id != findItem.id
          );
        }
      }

      if (!haveUpperCase) {
        const findItem = this.textPassError.find((data) => data.id === 4);

        findItem
          ? ""
          : this.textPassError.push({
              err: "รหัสต้องมีอักษรภาษาอังกฤษ พิมพ์ใหญ่ (A - Z)",
              id: 4,
            });
      } else {
        const findItem = this.textPassError.find((data) => data.id === 4);

        if (findItem) {
          this.textPassError = this.textPassError.filter(
            (data) => data.id != findItem.id
          );
        }
      }

      if (haveNumber && haveLowerCase && haveUpperCase && haveLength) {
        this.passwordEmpty = false;
      }
    },

    confirmPasswordChange(val) {
      if (val) {
        this.counterConfirmPass = val.length;

        if (val !== this.form.password) {
          this.textConfirmError = "กรุณากรอกให้ตรงกับ Password";
        } else {
          this.textConfirmError = "";
        }
      } else {
        this.counterConfirmPass = 0;
      }
    },

    approvalPasswordChange(val) {
      if (val) {
        this.counterApprovalPass = val.length;
        if (val.length < 6) {
          this.textApprovalError = "กรุณากรอกให้ครบ 6 หลัก";
          // clear validate confirmPass
          this.textConfirmError = "";
          this.counterApprovalPass = 0;
        } else {
          this.textApprovalError = "";
        }
      } else {
        this.counterApprovalPass = 0;
      }
    },

    formChange() {
      this.$emit("updateForm", this.form);
    },

    async checkDupEmail() {
      const response = await loginApi.register.checkEmailUser({
        email: this.form.email,
      });

      if (response.response_status === "ERROR") {
        this.isDupUser = true;
        this.responseDupErr = response.message;
        Swal.fire({
          icon: "error",
          title: this.responseDupErr,
          confirmButtonText: "ตกลง",
          timer: 5000,
        });
      } else {
        this.isDupUser = false;
      }
    },

    async checkDupName() {
      const response = await loginApi.register.checkUserName({
        username: this.form.username,
      });

      if (response.response_status === "ERROR") {
        this.isDupName = true;
        this.responseDupErr = response.message;
        Swal.fire({
          icon: "error",
          title: this.responseDupErr,
          confirmButtonText: "ตกลง",
          timer: 4000,
        });
      } else {
        this.isDupName = false;
      }
    },
  },
};
</script>

<style scoped>
#btnPass:hover {
  background-color: #56baed;
}

#btnConfirmPass:hover {
  background-color: #56baed;
}

#approvalPass:hover {
  background-color: #56baed;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  /* text-transform: uppercase; */
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* text-transform: uppercase; */
  font-size: 13px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 85%;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
  transform: scale(1.05);
}

input[type="text"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="password"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

input[type="password"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="password"]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    /* -webkit-transform: translate3d(0, -100%, 0); */
    /* transform: translate3d(0, -100%, 0); */
  }
  100% {
    opacity: 1;
    /* -webkit-transform: none; */
    /* transform: none; */
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    /* -webkit-transform: translate3d(0, -100%, 0); */
    /* transform: translate3d(0, -100%, 0); */
  }
  100% {
    opacity: 1;
    /* -webkit-transform: none; */
    /* transform: none; */
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* .fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
} */

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.fadeIn.fifth {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 1s;
}

.underlineHover:hover {
  cursor: pointer;
  color: #0d0d0d;
  transform: scale(1.1);
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}
.form-select {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
</style>
