<template>
  <!-- Login Form -->
  <div>
    <form id="formItems" class="needs-validation" autocomplete="off" novalidate>
      <input
        v-model="formCompany.company_business_id"
        disabled
        type="text"
        id="username"
        ref="username"
        class="form-control fadeIn second"
        placeholder="Business ID"
        required
      />
      <div
        v-if="!formCompany.company_business_id"
        class="invalid-feedback text-end pe-8 pb-3"
      >
        <span>กรุณากรอก</span>
      </div>
      <input
        v-model="formCompany.company_name"
        type="text"
        id="email"
        class="form-control fadeIn second"
        placeholder="ชื่อกิจการ"
        required
      />
      <div
        v-if="!formCompany.company_name"
        class="invalid-feedback text-end pe-8 pb-3"
      >
        <span>กรุณากรอก</span>
      </div>

      <!-- password -->
      <input
        v-model="formCompany.company_type"
        type="text"
        id="password"
        class="form-control fadeIn third"
        placeholder="ประเภทกิจการ"
        required
      />

      <div
        v-if="!formCompany.company_type"
        class="invalid-feedback text-end pe-8 pb-3"
      >
        <span>กรุณากรอก</span>
      </div>

      <textarea
        v-model="formCompany.company_address"
        type="text"
        id="company_address"
        class="fadeIn fourth"
        placeholder="ที่อยู่กิจการ"
      />

      <input
        v-model="formCompany.tax_id"
        type="text"
        id="tax_id"
        class="form-control fadeIn fourth"
        placeholder="Tax ID"
      />

      <input
        v-model="formCompany.company_tel"
        type="text"
        id="company_tel"
        class="form-control fadeIn fourth"
        placeholder="เบอร์โทรศัพท์กิจการ"
        required
      />
      <div
        v-if="!formCompany.company_tel"
        class="invalid-feedback text-end pe-8 pb-3"
      >
        <span>กรุณากรอก</span>
      </div>

      <input
        v-model="formCompany.company_email"
        type="text"
        id="company_email"
        class="form-control fadeIn fifth"
        placeholder="อีเมลกิจการ"
        required
      />
      <div
        v-if="!formCompany.company_email"
        class="invalid-feedback text-end pe-8 pb-3"
      >
        <span>กรุณากรอก</span>
      </div>

      <input
        v-model="formCompany.company_line_id"
        type="text"
        id="company_line_id"
        class="form-control fadeIn fifth"
        placeholder="ไลน์ไอดีกิจการ"
        required
      />
      <div
        v-if="!formCompany.company_line_id"
        class="invalid-feedback text-end pe-8 pb-3"
      >
        <span>กรุณากรอก</span>
      </div>

      <!-- <input
        v-model="formCompany.digital_address"
        disabled
        type="text"
        id="digital_address"
        class="fadeIn fifth"
        placeholder="Digital Address"
      /> -->

      <input
        @click="submit"
        type="submit"
        class="fadeIn fifth mt-10"
        value="ถัดไป"
      />
    </form>
  </div>
</template>

<script>
import loginApi from "@/api/user/";
import companyApi from "@/api/user/";
import emailApi from "@/api/email/";

import { Decode, Encode } from "@/services";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  props: {
    formCompany: Object,
    form: Object,
    step: Number,
  },
  emits: ["changeStep"],
  data: () => ({
    typePass: "password",
    showPassword: false,
    showConfirmPassword: false,
    isValid: false,

    addUserResponse: [],

    companyItems: [],

    passwordEmpty: true,

    counterPass: 0,
    textPassError: "",

    counterConfirmPass: 0,
    textConfirmError: "",

    isDupUser: false,
    responseDupErr: "",

    colorEyePass: "color: grey;",
    colorEyeConfirmPass: "color: grey;",
  }),

  mounted() {
    this.$refs.username.focus();
    this.generateData();
  },
  methods: {
    async generateData() {
      const response = await companyApi.company.generateNewData();

      if (response.response_status === "SUCCESS") {
        this.formCompany.company_business_id =
          response.data.company_business_id;
        this.formCompany.digital_address = response.data.digital_address;
      }
    },
    async submit() {
      let addResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      if (this.isValid) {
        document.getElementById("overlay").style.display = "block";

        const resCompany = await this.addCompany();

        if (resCompany) {
          this.addUser(resCompany.id);
          // const resAddUser = await this.addUser(resCompany.id);
          //   // if (resAddUser) {
          //   //   this.updateCompany(resCompany.id, resAddUser.id);
          //   // }
        }
      }
    },

    async addCompany() {
      let addResponse = [];

      addResponse = await loginApi.company.create(this.formCompany);
      if (addResponse.data) {
        if (addResponse.response_status === "SUCCESS") {
          return addResponse.data;
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "เพิ่มไม่สำเร็จ !",
          html: `${addResponse.message}`,
          confirmButtonText: "ตกลง",
        }).then(() => {
          document.getElementById("overlay").style.display = "none";
        });
      }
    },

    async addUser(comId) {
      let addResponse = [];
      delete this.form.confirmPassword;

      const payload = {
        ...this.form,
        companyId: comId,
      };

      addResponse = await loginApi.register.addUser(payload);
      if (addResponse.data) {
        // this.addUserResponse = addResponse;
        // return addResponse.data;
        if (addResponse.response_status === "SUCCESS") {
          this.$emit("setAfterRegister", addResponse.data);
          await this.sendVerify(addResponse.data);

          this.$emit("changeStep", 1);
          document.getElementById("overlay").style.display = "none";
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "เพิ่มไม่สำเร็จ !",
          html: `${addResponse.message}`,
          confirmButtonText: "ตกลง",
        }).then(() => {
          document.getElementById("overlay").style.display = "none";
        });
      }
    },
    async updateCompany(comId, userId) {
      const resUpdate = await loginApi.company.update(comId, {
        createBy: userId,
      });

      if (resUpdate.response_status === "SUCCESS") {
        this.$emit("setAfterRegister", this.addUserResponse.data);
        this.$emit("changeStep", 1);
        document.getElementById("overlay").style.display = "none";
      } else {
        Swal.fire({
          icon: "error",
          title: "เพิ่มไม่สำเร็จ !",
          html: `${this.addUserResponse.data}`,
          confirmButtonText: "ตกลง",
        }).then(() => {
          document.getElementById("overlay").style.display = "none";
        });
      }
    },

    checkFormIsEmpty() {
      !this.formCompany.company_name
        ? (this.isValid = false)
        : !this.formCompany.company_type
        ? (this.isValid = false)
        : !this.formCompany.company_tel
        ? (this.isValid = false)
        : !this.formCompany.company_email
        ? (this.isValid = false)
        : !this.formCompany.company_line_id
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },

    async sendVerify(item) {
      const response = await emailApi.email.verifyByEmail(item);
      console.log(response);
      if (response.response_status === "SUCCESS") {
        this.$emit("changeStep", 1);
      } else {
        Swal.fire({
          icon: "error",
          title: "สมัครใช้งานไม่สำเร็จ",
          confirmButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>

<style scoped>
#btnPass:hover {
  background-color: #56baed;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  /* text-transform: uppercase; */
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/
textarea {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

textarea:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* text-transform: uppercase; */
  font-size: 13px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 85%;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
  transform: scale(1.05);
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  /* -moz-transform: scale(0.95); */
  /* -webkit-transform: scale(0.95); */
  /* -o-transform: scale(0.95); */
  /* -ms-transform: scale(0.95); */
  /* transform: scale(0.95); */
}
input[type="text"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="password"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

input[type="password"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="password"]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    /* -webkit-transform: translate3d(0, -100%, 0); */
    /* transform: translate3d(0, -100%, 0); */
  }
  100% {
    opacity: 1;
    /* -webkit-transform: none; */
    /* transform: none; */
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    /* -webkit-transform: translate3d(0, -100%, 0); */
    /* transform: translate3d(0, -100%, 0); */
  }
  100% {
    opacity: 1;
    /* -webkit-transform: none; */
    /* transform: none; */
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* .fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
} */

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.fadeIn.fifth {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 1s;
}

.underlineHover:hover {
  cursor: pointer;
  color: #0d0d0d;
  transform: scale(1.1);
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}
.form-select {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
</style>
