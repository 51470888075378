<template>
  <div class="text-start fadeIn second ms-11" style="font-size: 14px">
    ค้นหากิจการ
  </div>
  <input
    v-model="searchCompany"
    type="text"
    id="username"
    ref="username"
    class="form-control fadeIn second"
    placeholder="ค้นหา"
    required
  />

  <div class="row mt-5 mb-5">
    <div class="col-8" style="display: flex; justify-content: space-between">
      <div
        class="form-check form-check-inline form-check-custom form-check-solid ms-10"
      >
        <input
          v-model="searchBy"
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          value="businessId"
          id="flexRadioChecked"
          checked="checked"
        />
        <label class="form-check-label me-4" for="flexRadioChecked"
          >Business ID</label
        >
        <input
          v-model="searchBy"
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          value="name"
          id="flexRadioChecked2"
        />
        <label class="form-check-label" for="flexRadioChecked2"
          >ชื่อกิจการ</label
        >
      </div>
    </div>

    <div class="col-3">
      <button
        type="button"
        class="btn btn-sm btn-light-primary"
        style="font-size: 14px; font-weight: bold; width: auto"
        @click="search"
      >
        ค้นหา
      </button>
    </div>
  </div>

  <!-- alert -->
  <div
    v-if="companyItem.length < 1"
    class="alert alert-dismissible bg-light-danger d-flex align-items-center p-5 ms-10 me-10"
  >
    <span class="svg-icon svg-icon-2hx svg-icon-danger me-3">
      <inline-svg src="media/icons/duotune/general/gen044.svg" />
    </span>
    <div class="d-flex flex-column">
      <h4 class="mb-1 text-danger">ไม่พบข้อมูลกิจการที่ค้นหา</h4>
    </div>
  </div>
  <!-- alert -->

  <div
    class="row mb-5"
    style="display: flex; justify-content: center"
    v-for="item in companyItem"
    :key="item.id"
  >
    <div
      class="col-10 pt-3 pb-3"
      style="
        border: 1px solid;
        border-color: Gainsboro;
        border-radius: 8px;
        cursor: pointer;
      "
      @click="companySelect = item"
    >
      <div
        class="form-check form-check-inline form-check-custom form-check-solid mt-3 mb-3"
      >
        <input
          v-model="companySelect"
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions2"
          id="flexRadioChecked2"
          :value="item"
          required
        />
        <label
          class="form-check-label ms-8"
          style="font-size: 14px"
          for="flexRadioChecked"
          >{{ item.company_name }}</label
        >
      </div>
      <select
        v-if="companyBranchItem.length"
        class="form-select"
        v-model="selectedBranch"
        id="validationTooltip01"
      >
        <option v-for="data in companyBranchItem" :key="data" :value="data.id">
          {{ data.company_branch_name }}
        </option>
      </select>
    </div>
  </div>
  <div
    v-if="companySelect === ''"
    class="text-end pe-8 pb-3 me-2"
    style="color: red"
  >
    <span>กรุณาเลือกบริษัท</span>
  </div>

  <div
    v-if="
      this.companyBranchItem.length &&
      this.companySelect &&
      !this.selectedBranch
    "
    class="text-end pe-8 pb-3 me-2"
    style="color: red"
  >
    <span>กรุณาเลือกสาขา</span>
  </div>

  <input
    @click="submit"
    type="submit"
    class="fadeIn fifth mt-10"
    value="ถัดไป"
  />
</template>

<script>
import loginApi from "@/api/user/";
import companyApi from "@/api/user/";

import { Decode, Encode } from "@/services";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  props: {
    form: Object,
    step: Number,
  },
  emits: ["changeStep"],
  data: () => ({
    searchBy: "businessId",
    searchCompany: "",

    companyItem: [],
    companyBranchItem: [],

    companySelect: "",
    selectedBranch: "",
  }),

  watch: {
    companySelect(val) {
      if (val) {
        this.getAllBranch(val.id);
      }
    },
  },

  mounted() {
    this.$refs.username.focus();
  },
  methods: {
    async getAllBranch(id) {
      this.loading = true;
      let responseData = [];

      try {
        responseData = await companyApi.companyBranch.getAll({
          companyId: id,
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.companyBranchItem = responseData.data.length
          ? responseData.data
          : [];
        this.selectedBranch = responseData.data.length
          ? responseData.data[0].id
          : "";
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async submit() {
      await this.validateForm();
      await this.checkFormIsEmpty();

      if (this.isValid) {
        this.form.companyId = this.companySelect.id;
        this.form.company = this.companySelect;
        this.form.companyBranchId = this.selectedBranch;

        this.$emit("changeStep", 1);
      }
    },
    checkFormIsEmpty() {
      if (
        this.companyBranchItem.length &&
        this.companySelect &&
        !this.selectedBranch
      ) {
        this.isValid = false;
      } else {
        !this.companySelect ? (this.isValid = false) : (this.isValid = true);
      }
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },

    async search() {
      const response = await companyApi.company.search({
        searchBy: this.searchBy,
        search: this.searchCompany,
      });

      if (response.response_status === "SUCCESS") {
        this.companyItem = response.data.length ? response.data : [];
      }
    },
  },
};
</script>

<style scoped>
#btnPass:hover {
  background-color: #56baed;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  /* text-transform: uppercase; */
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* text-transform: uppercase; */
  font-size: 13px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 85%;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
  transform: scale(1.05);
}

input[type="text"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="password"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

input[type="password"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="password"]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    /* -webkit-transform: translate3d(0, -100%, 0); */
    /* transform: translate3d(0, -100%, 0); */
  }
  100% {
    opacity: 1;
    /* -webkit-transform: none; */
    /* transform: none; */
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    /* -webkit-transform: translate3d(0, -100%, 0); */
    /* transform: translate3d(0, -100%, 0); */
  }
  100% {
    opacity: 1;
    /* -webkit-transform: none; */
    /* transform: none; */
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* .fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
} */

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.fadeIn.fifth {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 1s;
}

.underlineHover:hover {
  cursor: pointer;
  color: #0d0d0d;
  transform: scale(1.1);
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}
.form-select {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
</style>
